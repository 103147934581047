/* large text */
.text-truncate {
	overflow:      hidden;
	white-space:   nowrap;
	text-overflow: ellipsis;
}

.text-wrap {
	word-wrap:     break-word;
	overflow-wrap: break-word;
	word-break:    break-word;
	white-space:   pre-line;
}

/* End of large text*/
