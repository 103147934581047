/* taiga-ui */
:root {
	--tui-font-text:                  var(--as-font-family) !important;
	--tui-font-text-xs:               var(--as-font-family) !important;
	--tui-font-text-s:                var(--as-font-family) !important;
	--tui-font-text-m:                var(--as-font-family) !important;
	--tui-font-text-l:                var(--as-font-family) !important;
	--tui-font-text-xl:               var(--as-font-family) !important;
	--tui-text-primary:               var(--as-TextPrimary) !important;
	--tui-text-secondary:             var(--as-TextPrimary) !important;
	--tui-text-tertiary:              var(--as-TextPrimary) !important;
	--tui-background-base:            var(--as-BgPage) !important;
	--tui-background-base-alt:        var(--as-BgSecondaryExtra) !important;
	--tui-background-neutral-1:       var(--as-BgSecondaryExtra) !important;
	--tui-background-neutral-1-hover: var(--as-BgSecondaryExtra) !important;
	--tui-border-normal:              var(--as-BgSecondaryExtra) !important;
}

.t-button {
	width: 100%;
	color: var(--as-TextPrimary);

	.t-wrapper {
		width: 100%;

		.t-text {
			flex:       1;
			text-align: start;
		}
	}
}

.t-wrapper_hoverable:hover, .t-wrapper_hoverable.t-wrapper_hovered {
	filter: brightness(0.8);
}

tui-hints {
	z-index:                        1;
	--tui-background-accent-1:      var(--as-BgTooltip);
	--tui-text-primary-on-accent-1: var(--as-TextContrastThemed);
	--tui-radius-l:                 0.625rem;
}

tui-ring-chart {
	color: var(--as-BgTertiary);
}

tui-axes {
	.t-label-x {
		text-align:  center;
		white-space: nowrap;
	}

	.t-label-y:last-child {
		margin-top: 0 !important;
	}
}

/* End of taiga-ui */
