/* dimensions */
.w-100 {
	width: 100%;
}

.h-100 {
	height: 100%;
}

/* End of dimensions */
