alaris-table thead tr > th:first-child {
	width: max-content !important;
	width: -moz-max-content !important;
}

alaris-pagination {
	border-radius: var(--as-border-radius-L);
}

.table-management {
	position:        relative;
	display:         flex;
	align-items:     center;
	flex-direction:  row;
	flex-wrap:       wrap;
	justify-content: space-between;
	padding: var(--as-root-padding);
	gap:             1rem;

	.left {
		display:         flex;
		align-items:     center;
		flex-direction:  row;
		flex-wrap:       wrap;
		justify-content: flex-start;
		justify-self:    flex-start;
		gap:             1rem;
	}

	.right {
		display:         flex;
		align-items:     center;
		flex-direction:  row;
		flex-wrap:       wrap;
		justify-content: flex-end;
		justify-self:    flex-end;
		gap:             1rem;
		margin-left:     auto
	}

	.indicator {
		width:            0.5rem;
		height:           0.5rem;
		border-radius:    50%;
		background-color: var(--as-IconAccent);
	}
	
	.total {
		font-weight: 600;
	}
}
